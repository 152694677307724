import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import HeadHelmet from '../../components/head';
import Layout from '../../components/layout';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';

const WoodPelletsPage = ({ data }) => {
  const { strapiSsWoodPellets } = data;

  return (
    <Layout>
      <HeadHelmet data={strapiSsWoodPellets.MetaData} />
      <StyledBackground
        img={strapiSsWoodPellets.hero_image_wood_pellets.childImageSharp.fluid}
        title={strapiSsWoodPellets.title}
        titleWidth="100%"
      />

      <div className={'SharedEl__textContent--center'}>
        <div className={'SharedEl__headingsWidth'}>
          <h2 className={'SharedEl__underline'}>{strapiSsWoodPellets.section_1_title}</h2>
        </div>
        <div>
          <ReactMarkdown>{strapiSsWoodPellets.section_1_content}</ReactMarkdown>
        </div>
      </div>
      <div>
        <Img
          className={'Wood__container--woodSecondImageHelper'}
          fluid={strapiSsWoodPellets.section_1_image.childImageSharp.fluid}
        />
      </div>
      <div className={'Wood__desktopGrid'}>
        <div>
          <Img
            className={'Wood__container--woodThirdImageHelper'}
            fluid={strapiSsWoodPellets.section_2_image.childImageSharp.fluid}
          />
        </div>
        <div className={'SharedEl__container--greyBG'}>
          <div
            className={
              'SharedEl__textContent--center SharedEl__textContent--leftNormalMargin SharedEl__listStyle'
            }
          >
            <div>
              <div className={'SharedEl__headingsWidth'}>
                <h2 className={'SharedEl__underline'}>{strapiSsWoodPellets.section_2_title}</h2>
              </div>
              <ReactMarkdown className={'Wood__listHelper Wood__subUlHelper'}>
                {strapiSsWoodPellets.section_2_content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
      <div className={''}>
        <div className={'Wood__desktopGrid'}>
          <div className={'SharedEl__container'}>
            <div
              className={
                'SharedEl__textContent--center SharedEl__textContent--leftNormalMargin SharedEl__listStyle'
              }
            >
              <div>
                <div className={'SharedEl__headingsWidth'}>
                  <h2 className={'SharedEl__underline'}>{strapiSsWoodPellets.section_3_title}</h2>
                </div>
                <div className={'SharedEl__ulHelper Wood__marginUlHelper'}>
                  <ReactMarkdown className={'Wood__listHelper'}>
                    {strapiSsWoodPellets.section_3_content}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Img
              className={'Wood__container--woodFourthImageHelper'}
              fluid={strapiSsWoodPellets.section_3_image.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'Wood__mailContactDiv'}>
          <h2>{strapiSsWoodPellets.section_4_title}</h2>
          <div className={'Wood__icon'}>
            <a href={`mailto:${strapiSsWoodPellets.section_4_email}`}>
              <i className="fas fa-envelope-open-text" />
              {strapiSsWoodPellets.section_4_email}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WoodPelletsPage;

export const pageQuery = graphql`
  query {
    strapiSsWoodPellets {
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image_wood_pellets {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_title
      section_1_content
      section_2_title
      section_2_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_2_content
      section_3_title
      section_3_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_3_content
      section_4_title
      section_4_email
    }
  }
`;
